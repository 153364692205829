/* Hide scrollbar for WebKit (Chrome, Safari) */
*::-webkit-scrollbar {
 display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
 -ms-overflow-style: none; /* IE and Edge */
 scrollbar-width: none; /* Firefox */
}
